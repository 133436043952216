<template>
    <div class="trainings-block h-[293px] border-red-400">
        <div class="text-[#FFF] relative left-[4px] text-[15px] font-bold text-start">
            НАСТУПНІ ТРЕНУВАННЯ
        </div>
        <div class="mt-[5px] h-[268px] overflow-auto px-[5px] message-box">
            <div v-for="training in store.planedTrainingsData" class="w-full flex h-[24px] items-center rounded-[4px] border-[1px] border-[#48E5C2] mb-[9px]">
                <div class="h-full rounded-l-[4px] bg-[#48E5C2] w-[5px]"></div>
                <div class="px-[1vw] w-full flex text-[#FFF] align-middle">
                    <div class="w-[10%] align-middle text-[14px]">
                        {{ getWeekDay(training.date) }}.
                    </div>
                    <div class="w-[30%] text-[14px] text-center font-semibold align-middle">
                        {{ training.date.split('T')[0].split('-').reverse().join('.') }}
                    </div>
                    <div class="w-[30%] text-[14px] text-center align-middle">
                        {{ training.start_time }}
                    </div>
                    <div class="w-[30%] text-[14px] truncate text-end align-middle">
                        {{ training.location_label }}
                    </div>
                </div>
            </div>
            <div v-for="training in (8 - store.planedTrainingsData.length > 0 ? 8 - store.planedTrainingsData.length : 0)" class="w-full flex h-[24px] items-center rounded-[4px] border-[1px] border-[#47484966] mb-[9.2px]">
                <div class="h-full rounded-l-[4px] bg-gray-400 w-[5px]"></div>
                <div class="px-[1vw] w-full flex text-[#FFF] align-middle">
                    <div class="w-full text-[14px] opacity-60 text-center align-middle">
                        Відсутнє заплановане тренування
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useMainStore } from '../../store/mainStore'
    
    export default {
        name: 'TrainingsBlock',

        setup() {
            const store = useMainStore()

            const getWeekDay = (dateString) => {
                const daysOfWeek = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const date = new Date(dateString)
                const dayOfWeek = daysOfWeek[date.getDay()]
                return dayOfWeek
            }
            return {
                store,
                getWeekDay
            }
        }
    }
</script>

<style lang="scss" scoped>


    @media (min-width: 0px) and (max-width: 1800px) {
        .trainings-block {
            @apply w-[309px];
        }
    }

    @media (min-width: 1800px) and (max-width: 8000px) {
        .trainings-block {
            @apply w-[296px];
        }
    }

</style>