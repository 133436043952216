<template>
  <router-view/>
</template>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #211C1F;
  }
</style>