<template>
    <div class="">
        <div @click="$router.push('/'); store.getStudentData()" class="flex text-[#FECC00] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>
        <div v-if="store.studentBalance < 0" class="flex flex-col justify-center px-[10px] mt-[40px]">
            <div class="bg-[#FECC00] mt-4 rounded-[9px] flex justify-between text-[#211C1F] text-center px-3 py-2 w-full text-base font-semibold ">
                <div>{{ store.student.name + ' ' + store.student.surname }}</div>
                <div>{{ store.student.group_name }}</div>
            </div>
            <div class="py-[20px] outline-none border-none text-[31px] font-semibold text-[#FFF] bg-transparent placeholder:text-[#FFF] placeholder:opacity-60  text-center">{{ store.studentBalance.toFixed(2) }}₴</div>
            <div class="text-[#FFF] text-sm text-center">(оплата за попредній місяць)</div>
            <button @click="createDebtTransaction" class="bg-[#48E5C2] mt-4 rounded-[9px] text-white px-3 py-2 w-full text-base font-semibold flex items-center align-middle justify-between">
                <div class="w-full">Сплатити</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
                </svg>

            </button>
        </div>
        <div class="flex flex-col justify-center px-[10px] mt-[40px]">
            <div class="bg-[#FECC00] mt-4 rounded-[9px] text-[#211C1F] text-center px-3 py-2 w-full text-base font-semibold ">Ввести іншу суму</div>
            <input v-model="amount" type="number" min="0.01" step="0.01" class="py-[20px] outline-none border-none text-[31px] font-semibold text-[#FFF] bg-transparent placeholder:text-[#FFF] placeholder:opacity-60  text-center" placeholder="0.00₴">
            <div class="text-[#FFF] text-sm text-center">(введіть суму, яку необхідно провести)</div>
            <button @click="createTransaction" class="bg-[#48E5C2] mt-4 rounded-[9px] text-white px-3 py-2 w-full text-base font-semibold flex items-center align-middle justify-between">
                <div class="w-full">Сплатити</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="white"/>
                </svg>

            </button>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { createWayforpayTransaction } from '@/mobileApp/services/apiRequests'

    export default {
        name: 'payment-view',
        
        setup() {
            const store = useMainStore()
            const router = useRouter()
            const amount = ref(null)

            const createTransaction = async () => {
                // const res = await createWayforpayTransaction(
                //     amount.value,
                //     store.student.student_id,
                //     store.student.name,
                //     store.student.surname,
                //     store.student.email,
                //     store.student.phone
                // )
                // console.log('res.data ---------- ', res.data)
                // window.open(res.data.payment.url, '_parent')
                // router.push('/payment-redirect')
                alert('Платіжна система не підключена')
            }

            const createDebtTransaction = async () => {
                // const debt = store.studentBalance * -1
                // const res = await createWayforpayTransaction(
                //     debt,
                //     store.student.student_id,
                //     store.student.name,
                //     store.student.surname,
                //     store.student.email,
                //     store.student.phone
                // )
                // console.log('res.data ---------- ', res.data)
                // window.open(res.data.payment.url, '_parent')
                // router.push('/payment-redirect')
                alert('Платіжна система не підключена')
            }

            return {
                amount,
                router,
                store,
                createTransaction,
                createDebtTransaction
            }
        }
    }
</script>
