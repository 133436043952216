<template>
    <div class="w-full bg-[#FECC00] py-[8px] z-10 absolute">
        <!-- <div class="flex mx-[13px] mt-1 mb-[6px] justify-between">
            <div class="text-2xl text-white font-bold inline-block">{{ studentName }}</div>
            <div class="flex justify-end w-[20%]">
                <div class="flex" >
                    <div class="flex text-white items-center align-middle">
                        <img src="@/mobileApp/assets/logo.svg" class="w-[30px]">
                        <div class="mt-[-2px]">
                            |
                        </div>
                        <svg class="ml-2" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 2C0 0.89543 0.895431 0 2 0H18.7692C19.8738 0 20.7692 0.895431 20.7692 2V7.8H0V2Z" fill="#FECC00"/>
                            <path d="M0 7.79999H20.7692V13C20.7692 14.1046 19.8738 15 18.7692 15H2C0.895431 15 0 14.1046 0 13V7.79999Z" fill="#E6EE5B"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="flex justify-between mx-3 items-center">
            <div class="text-[#2B2A29] text-center inline-block">
                <img src="../../assets/bell_icon.svg" @click="$router.push('/messages')" class="bell">
            </div>
            <div class="text-center inline-block">
                <div class="text-xs text-[#5F4510]">
                    група
                </div>
                <div class="font-bold text-[#2B2A29]">
                    {{ studentGroup ? studentGroup : '—'  }}
                </div>
            </div>

            <div class="text-center inline-block">
                <div class="text-xs text-[#5F4510]">
                    вік
                </div>
                <div class="font-bold text-[#2B2A29]">
                    {{ studentAge ? studentAge : '—' }}
                </div>
            </div>

            <div class="text-center inline-block">
                <div class="text-xs text-[#5F4510]">
                    амплуа
                </div>
                <div class="font-bold text-[#2B2A29]">
                    {{ studentAmplua ? studentAmplua : '—' }}
                </div>
            </div>

            <div class="text-center inline-block">
                <div class="text-xs text-[#5F4510]">
                    номер
                </div>
                <div class="font-bold text-[#2B2A29]">
                    {{ studentNumber ? studentNumber : '—' }}
                </div>
            </div>

            <div class="text-center inline-block">
                <div class="text-xs text-[#5F4510]">
                    тренер
                </div>
                <div class="font-bold text-[#2B2A29]">
                    {{ trainerName ? trainerName : '—' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'general-information-bar',

        props: {
            trainerName: {
                type: String,
                default: ''
            },
            studentNumber: {
                type: String,
                default: ''
            },
            studentAmplua: {
                type: String,
                default: ''
            },
            studentAge: {
                type: Number,
                default: 0
            },
            studentGroup: {
                type: String,
                default: ''
            },
            studentName: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bell {
        filter: drop-shadow(0px 4px 4px rgb(0 0 0 / 0.4));
    }
</style>