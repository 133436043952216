<template>
    <router-view/>
</template>

<script>
    import { ref, onBeforeMount } from 'vue'
    import { useMainStore } from './store/mainStore'

    export default {
        setup() {
            const store = useMainStore()

            onBeforeMount(async () => {
                console.log('before mount')
                const studentRes = await store.getStudentData()
                console.log(studentRes)
                if (!studentRes) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                    return
                }
                await store.getGroupData()
                await store.getTrainerData()
                await store.getPlanedTrainings()
                await store.getStudentIncomes()
                await store.getBalanceState()
                await store.getTelegramBotSubscribed()
            })
        }        
    }
</script>

<style lang="scss">
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    body {
        background-color: #211C1F; 
        overflow-x: hidden;
    }

    body::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    body::-webkit-scrollbar-track {
        background: #211C1F;        /* цвет зоны отслеживания */
    }

    body::-webkit-scrollbar-thumb {
        background-color: #211C1F;    /* цвет бегунка */
        border-radius: 20px;
    }

    @font-face {
        font-family: 'Avenir Regular';
        src: url('./assets/Avenir-regular.ttf');
    }

    @font-face {
        font-family: 'Avenir Bold';
        src: url('./assets/Avenir-bold.ttf');
    }
    
</style>