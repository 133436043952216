<template>
    <div class="">
        <div class="w-full h-[22px] relative py-[2px] border-[1px] flex border-[#47484966] rounded-[2vw]">
            <div class="block bg-[#48E5C2] h-[16px] trainings-percent-width rounded-l-[2vw] ml-[2px] mb-[2px]"></div>
            <div class="w-px h-[30px] bg-[#FFF]">
            </div>
            <div class="absolute text-[1vw] top-[30px] left-gap text-[#FFF]">
                {{ store.trainingsAttendedProcent > 0 ? store.trainingsAttendedProcent.toFixed(0) : 0  }}%
            </div>
        </div>
        <div 
            class="w-full text-[0.8vw] text-[#FFF]"
            :class="{
                'text-end' : isNaN(store.attendedTrainingsPercent) || store.attendedTrainingsPercent < 50,
                'text-start' : store.attendedTrainingsPercent >= 50,
            }"    
        >
            відвідування
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useMainStore } from '../../store/mainStore'

    export default {
        name: 'TrainingAttending',

        setup(props, { emit }) { 
            const store = useMainStore()
            const trainingPercent = ref(`${store.trainingsAttendedProcent - 2.5 > 0 ? store.trainingsAttendedProcent - 2.5 : 0}%`)
            const leftGap = ref(`${store.trainingsAttendedProcent - 7 > 0 ? store.trainingsAttendedProcent - 7 : 0 }%`)

            onMounted(() => {
                console.log('store.attendedTrainingsPercent', store.trainingsAttendedProcent)
            })

            return {
                store,
                leftGap,
                trainingPercent
            }
        }
    }
</script>

<style scoped>

    .trainings-percent-width {
        width: v-bind(trainingPercent);
    }

    .left-gap {
        left: v-bind(leftGap);
    }

</style>
