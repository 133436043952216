<template>
    <div 
        v-if="day.type != 'empty'" 
        class="relative m-[2px] flex flex-col w-[160px] h-[140px] border-[1px] border-[#47484966] group"
    >
        <div 
            class="mx-[4px] mt-[2px] text-[14px] flex items-center justify-center align-middle w-6 h-6 rounded-full text-center font-bold"
            :class="{
                'border-[1px] rounded-full bg-[#FECC00] text-[#17264F]': day.is_current,
                'text-[#FFF] ': !day.is_current,
            }"
        >{{ day.dayNumber }}</div>
        <div class="flex flex-col px-1 overflow-auto w-full">
            <div class="w-full overflow-hidden h-[86px] mt-[2px]">
                <button 
                    v-for="(training, id) in day.trainings" 
                    @click="$emit('viewFull', day)"
                    :class="{
                        'border-[1px] rounded-[4px] border-[#48E5C2]': id === 0,
                        'border-[1px] rounded-[4px] border-red-100 text-red-100': training.status === 'missed',
                        'border-[1px] rounded-[4px] border-green-100 text-green-100': training.status === 'completed',
                        'text-[#48E5C2]' : training.status != 'missed' && training.status != 'completed'
                        }"
                    class="flex items-center w-[132px] h-[16px] flex-shrink-0 mb-[7px] px-[4px] text-xs cursor-default mx-auto"
                >
                    <div class="w-[20%] text-start font-light leading-none">{{ training.start_time }}</div>
                    <div class="w-[40%] px-2 text-center font-bold leading-none truncate">{{ training.group_label }}</div>
                    <div class="w-[40%]  text-start font-light leading-none truncate">{{ training.location_label }}</div>
                </button>
            </div>
        </div>
        <!-- <div v-if="day.trainings.length >= 3" class="flex select-none justify-center h-[2px] relative top-[-18px] items-top">
            <div class="text-center align-top leading-none h-[2px] select-none">...</div>
        </div> -->
        <div class="flex absolute w-[90%] left-[50%] translate-x-[-50%] bottom-0  pt-2">
            <div></div>
            <button @click="$emit('viewFull', day)" class=" bottom-0 right-0 flex items-center justify-center w-6 h-6 text-white rounded group-hover:flex ">
                <img src="../../assets/new_open_icon.svg" alt="">
            </button>

        </div>
    </div>
    <div v-else class="relative m-[2px] flex flex-col border-[1px] border-[#47484966] group w-[160px] h-[140px] bg-[#010101] blur-[1px] bg-opacity-10">
        <div 
            class="mx-[4px] my-1 text-base align-middle w-6 h-6 text-[#FFF] rounded-full text-[16px] text-center font-bold"
        >{{ day.month_day }}</div>
    </div>
</template>
<script>
    import { ref, onMounted, onUpdated } from 'vue'

    export default {
        name: 'new-day-card',

        props: {
            day: {
                type: [Object, null],
                required: true
            }
        },

        setup(props) {
            const currentDay = ref({})
            
            onMounted(() => {
                console.log('big calendar days', props.day)
                if (props.day.type != 'empty') {
                    currentDay.value = new Date(new Date(props.day.dateObj).setDate(props.day.dateObj.getDate() + 1)).toISOString().split('T')[0]
                    // currentDay.value = currentDay.value.toISOString().split('T')[0]
                }
            })

            onUpdated(() => {
                if (props.day.type != 'empty') {
                    currentDay.value = new Date(new Date(props.day.dateObj).setDate(props.day.dateObj.getDate() + 1)).toISOString().split('T')[0]
                    // currentDay.value = currentDay.value.toISOString().split('T')[0]
                }
            })

            return {
                today: new Date().toISOString().split('T')[0],
                currentDay
            }
        }

    }
</script>