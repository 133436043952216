<template>
    <div >
        <div v-if="componentName != 'LoginView' && componentName != 'Loading'">
            <div class="flex">
                <Sidebar v-if="windowWidth > 1300" class="w-[250px] mr-[250px]" />
                <MinSidebar v-else class="w-[100px]"/>
                <div :class="{
                    'w-[100%]' : windowWidth <= 1500,
                    'w-full' : windowWidth > 1500
                }">
                    <component :is="componentName" :class="{'ml-[150px]' : windowWidth <= 1300, 'ml-[250px]' : windowWidth > 1300}"></component>
                </div>
            </div>
        </div>
        <div v-else>
            <component :is="componentName"></component>
        </div>
    </div>
</template>

<script>
    import {ref, onMounted, onUpdated} from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import Sidebar from '../components/Sidebar.vue' 
    import MinSidebar from '../components/MinSidebar.vue'
    import MainPage from '../views/MainPage.vue'
    import FinancePage from '../views/FinancePage.vue'
    import CalendarPage from '../views/CalendarPage.vue'
    import SettingsPage from '../views/SettingsPage.vue'
    import TeamPage from '../views/TeamPage.vue'
    import LoginView from '../views/LoginView.vue'
    import AgreementPersonalDataView from '../views/agreements/AgreementPersonalDataView.vue'
    import SchoolRulesView from '../views/agreements/SchoolRulesView.vue'
    import Header from '../components/Header.vue'

    export default {
        name: 'MainLayout',
        setup(props, { emit }) {
            const route = useRoute()
            const router = useRouter()
            const windowWidth = ref(0)
            const isSidebarVisible = ref(false)
            const isLoginSuccess = ref(false)

            //Login routing            
            const componentName = ref('LoginView')
            onMounted(async () => {
                if (localStorage.access_token && localStorage.refresh_token) {                    
                    componentName.value = route.meta.component
                } else {
                    console.log('no tokens')
                    componentName.value = 'LoginView'
                }
                windowWidth.value = window.innerWidth

                window.addEventListener('resize', () => {
                    windowWidth.value = window.innerWidth
                })
            })

            onUpdated(() => {
                if (localStorage.access_token && localStorage.refresh_token) {
                    componentName.value = route.meta.component
                }
            })

            return {
                windowWidth,
                isSidebarVisible,
                isLoginSuccess,
                componentName
            }
        },

        components: {
            MainPage,
            Sidebar,
            FinancePage,
            CalendarPage,
            SettingsPage,
            TeamPage,
            LoginView,
            AgreementPersonalDataView,
            SchoolRulesView,
            MinSidebar,
            Header
        }
    }
</script>

<style lang="scss">
    .message-box::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .message-box::-webkit-scrollbar-track {
        background: #211C1F;        /* цвет зоны отслеживания */
    }

    .message-box::-webkit-scrollbar-thumb {
        background-color: #211C1F;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }
</style>