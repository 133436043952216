<template>
    <div v-if="loading" class="bg-[#211C1F] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#BD252C] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div v-else>
        <div @click="$router.push('/')" class="flex text-[#FECC00] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="text-[#FECC00] mt-[10px]">
            <div class="text-center">
                {{ store.group.name }}
            </div>
            <div class="w-[90%] mx-auto mt-[10px] h-px bg-[#FECC00]"></div>
        </div>

        <div class="flex items-center justify-center mt-[25px]">
            <div class="flex items-center">
                <div class="flex">
                    <div class="flex flex-col items-center">
                        <img src="../assets/logo.svg" class="w-[20px] mb-[7px]">
                        <div class="w-[80%] mx-auto h-[1px] bg-white mb-[6px]"></div>
                        <img src="../assets/ukraine_flag.svg" class="w-[20px]">
                    </div>
                </div>
                <div class="text-white ml-[11px] relative top-[4px] text-[16px] font-medium leading-tight">
                    <div>{{ store.trainer.name }}</div>
                    <div>{{ store.trainer.surname }}</div>
                </div>
            </div>
            <div class="relative top-[4px] ml-[6px]">
                <div class="w-[43px] overflow-hidden h-[43px] flex items-end justify-center rounded-full border-[#FECC00] border-[2px]">
                    <img v-if="store.trainer.photo?.length > 0" :src="apiUrl + 'static/' + store.trainer.photo">
                    <div v-else class="w-full h-full bg-[#FECC00] bg-opacity-40 text-[#FECC00] text-[16px] font-semibold flex justify-center items-center">
                        {{ store.trainer?.surname?.length > 0 ? store.trainer.name[0] + store.trainer.surname[0] : '?' }}
                    </div>
                </div>
            </div>
        </div>

        <TeamField />

        <button @click="$router.push('/all-students-in-group')" class="border-[#48E5C2] border-[1px] text-[#48E5C2] rounded-[9px] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mt-[30px] mx-auto">
            <div class="w-full">Склад</div>
        </button>
    </div>
</template>

<script>
    import { useMainStore } from '../store/mainStore'
    import { ref, onMounted } from 'vue'
    import TeamField from '../components/TeamPage/TeamField.vue'


    export default {
        name: 'TeamView',

        setup(props, { emit }) {
            const loading = ref(true)
            const store = useMainStore()
            const apiUrl = process.env.VUE_APP_API_URL

            onMounted(async () => {
                await store.getAllGroupStudents()
                loading.value = false
            })

            return {
                store,
                loading,
                apiUrl  
            }
        },

        components: {
            TeamField
        }
    }
</script>

<style lang="scss" scoped>

</style>