<template>
    <div class="flex justify-between items-center bg-[#211C1F] z-[50] py-[4px] w-full">
        <div class="text-zinc-100 text-[20px] font-extrabold">
            {{ pageName }}
        </div>
        <div class="flex">
            <div class="text-[#FECC00] mr-[35px] text-[15px] font-medium flex justify-center items-center">
                <div>{{ store.student.name_eng + ' ' + store.student.surname_eng }}</div>
            </div>
            <div class="w-[46px] overflow-hidden h-[46px] flex items-center justify-center rounded-full border-[#FECC00] border-[1.5px]">
                <img v-if="store.student.photo?.length > 0" :src="photo" class="">
                <div v-else class="w-full h-full bg-[#FECC00] bg-opacity-40 text-[#FECC00] font-semibold flex justify-center items-center">
                    {{ store.student.name[0] + store.student.surname[0] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onBeforeMount } from 'vue'
    import { useRoute } from 'vue-router'
    import { useMainStore } from '../store/mainStore'

    export default {
        name: 'student-header',

        setup() {
            const store = useMainStore()
            const apiURL = process.env.VUE_APP_API_URL
            const photo = ref(apiURL + 'static/' + store.student.photo)
            const route = useRoute()
            const pageName = ref('')

            onBeforeMount(() => {
                switch(route.meta.component) {
                    case 'MainPage':
                        pageName.value = 'Показники'
                        break
                    case 'FinancePage':
                        pageName.value = 'Фінанси'
                        break
                    case 'TeamPage':
                        pageName.value = 'Моя команда'
                        break
                    case 'CalendarPage':
                        pageName.value = 'Календар'
                        break
                    case 'SettingsPage':
                        pageName.value = 'Налаштування'
                        break
                }
            })

            return {
                store,
                photo,
                pageName
            }
        }
    }
</script>
