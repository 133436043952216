<template>
    <div class="bg-[#211C1F] absolute pb-5 h-[920px] w-full">
        <div @click="$router.push('/'); store.getStudentData()" class="flex text-[#FECC00] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>
        <div class="mt-4 mb-4">
                <div class="flex items-center">
                    <div class="h-px w-[20%] bg-[#FECC00]"></div>
                    <div class="inline-block w-[60%] text-center text-sm font-semibold text-[#FECC00]">
                        ІНФОРМАЦІЯ ПРО ГРАВЦЯ
                    </div>
                    <div class="h-px w-[20%] bg-[#FECC00]"></div>
                </div>
                <div class="mb-3">
                    <div class="student-data-table mt-4 mx-4 border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-6 py-1">
                        <div class="flex text-[#FFF] justify-between items-center py-3">
                            <div class="font-bold text-lg">{{ store.student.name }}</div>
                            <div class="font-light text-sm">ім'я</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg">{{ store.student.surname }}</div>
                            <div class="font-light text-sm">прізвище</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg">{{ store.student.patronymic }}</div>
                            <div class="font-light text-sm">по батькові</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg"><input type="text" class="bg-transparent outline-none text-[#FECC00] w-[60%] placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.name_eng" placeholder="Ім'я англійською"></div>
                            <div class="font-light text-sm">name</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg"><input type="text" class="bg-transparent outline-none text-[#FECC00] w-[80%] placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.surname_eng" placeholder="Фамілія англійською"></div>
                            <div class="font-light text-sm">surname</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg"><input type="text" class="bg-transparent outline-none text-[#FECC00] w-[60%] placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.passport" placeholder="Номер паспорту"></div>
                            <div class="font-light text-sm">паспорт</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg w-[50%]"><input type="text" class="bg-transparent outline-none text-[#FECC00] w-[90%] placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.id_number" placeholder="ID номер"></div>
                            <div class="font-light text-sm">ID код</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <!-- <div class="font-bold text-lg">{{ passportDate.split('T')[0].split('-').reverse() }}</div> -->
                            <div class="font-bold text-lg w-[46%] truncate"><input type="date" :value="passportDate.split('T')[0]" @change="updatePassportDate($event)" class="bg-transparent truncate outline-none text-[#FECC00] w-[95%] placeholder:text-[#FECC00] placeholder:opacity-40"></div>
                            <div class="font-light text-sm">закінчення паспорту</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg w-[56%] truncate"><input type="text" class="bg-transparent truncate outline-none text-[#FECC00] w-[95%] placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.patrons[0].name" placeholder="Ім'я"></div>
                            <div class="font-light text-sm">відпов. особа</div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <div class="font-bold text-lg w-[50%]"><input type="text" class="bg-transparent outline-none text-[#FECC00] placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.patrons[0].phone_number" placeholder="Номер телефону"></div>
                            <div class="font-light text-sm">телефон в.о. </div>
                        </div>
                        <div class="flex text-[#FFF] justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                            <!-- <div class="font-bold text-lg">{{ passportDate.split('T')[0].split('-').reverse() }}</div> -->
                            <div class="font-bold text-lg w-[46%] truncate"><input type="date" :value="studentBirthday.split('T')[0]" @change="updateStudentBirthday($event)" class="bg-transparent truncate outline-none text-[#FECC00] w-[95%] placeholder:text-[#FECC00] placeholder:opacity-40"></div>
                            <div class="font-light text-sm">день народження</div>
                        </div>
                    </div>
                    <div class="border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-3   py-2 mx-4 mt-2">
                        <div>
                            <div class="border-b-[0.2px] border-[#A5A3A3] border-opacity-25 grid grid-cols-12 w-full">
                                <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4"><span class="font-bold text-lg"><input type="text" class="font-bold text-lg text-center bg-transparent outline-none text-[#FECC00] w-full placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.height" placeholder="0.00"></span><br><span class="text-[10px] font-light">зріст, см</span></div>
                                <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4"><span class="font-bold text-lg"><input type="text" class="font-bold text-lg text-center bg-transparent outline-none text-[#FECC00] w-full placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.weight" placeholder="0.00"></span><br><span class="text-[10px] font-light">вага, кг</span></div>
                                <div class="text-[#FFF]  pl-3 py-2 text-center col-span-4"><span class="font-bold text-lg"><input type="text" class="font-bold text-lg text-center bg-transparent outline-none text-[#FECC00] w-full placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.foot_size" placeholder="0.00"></span><br><span class="text-[10px] font-light">розмір ноги</span></div>
                            </div>
                            <div class="grid grid-cols-12">
                                <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4"><span class="font-bold text-lg"><input type="text" class="font-bold text-lg text-center bg-transparent outline-none text-[#FECC00] w-full placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.leading_foot" placeholder="0.00"></span><br><span class="text-[10px] font-light">нога</span></div>
                                <div class="text-[#FFF] border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4"><span class="font-bold text-lg"><input type="text" class="font-bold text-lg text-center bg-transparent outline-none text-[#FECC00] w-full placeholder:text-[#FECC00] placeholder:opacity-40" v-model="store.student.breast_volume" placeholder="0.00"></span><br><span class="text-[10px] font-light">об'єм гр., см</span></div>
                                <div class="text-[#FFF]  pl-3 py-2 text-center col-span-4"><span class="font-bold text-lg">{{ store.student.start_training.split('T')[0].split('-').reverse().join('.') }}</span><br><span class="text-[10px] font-light">початок тренувань</span></div>
                            </div>
                        </div>
                    </div>
                    <button @click="updateStudent" class="bg-[#48E5C2] rounded-[9px] text-black px-3 py-2 w-[92%] text-base font-semibold flex items-center align-middle justify-between mt-5 mx-auto">
                        <div class="w-full">Зберегти</div>
                    </button>
                </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { useMainStore } from '@/mobileApp/store/mainStore' 
    import { updateStudentRequest } from '@/mobileApp/services/apiRequests'

    export default {
        name: 'EditData',
        setup() {
            const route = useRoute()
            const router = useRouter()
            const store = useMainStore()

            const cacheStudentData = Object.assign({}, store.student)
            const unformattedPassportDate = ref(new Date(store.student.passport_end_date))
            const passportDate = ref(new Date(unformattedPassportDate.value.setDate(unformattedPassportDate.value.getDate() + 1)).toISOString())

            const unformattedStudentBirthday = ref(new Date(store.student.birthday))
            const studentBirthday = ref(new Date(unformattedStudentBirthday.value.setDate(unformattedStudentBirthday.value.getDate() + 1)).toISOString())

            const updateStudentBirthday = (e) => {
                studentBirthday.value = new Date(e.target.value).toISOString()
            }

            const updateStudent = async () => {
                store.student.passport_end_date = passportDate.value
                store.student.birthday = studentBirthday.value
                const response = await updateStudentRequest(
                    store.student, 
                    store.student.passport_files, 
                    store.student.id_code_files, 
                    store.student.attorney_child_files, 
                    store.student.photo_for_passport
                    )
                router.push('/')
            }

            const updatePassportDate = (e) => {
                console.log(e.target.value)
                passportDate.value = new Date(e.target.value).toISOString()
            }

            return {
                store,
                cacheStudentData,
                updateStudent,
                passportDate,
                updatePassportDate,
                studentBirthday,
                updateStudentBirthday
            }
        }
    }
</script>